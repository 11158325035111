export default {
  "header": "qxg",
  "btn-preset": "qxD btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qxV",
  "statements-table": "qxL",
  "no-result": "qxT",
  "lottie-illustration": "qxz",
  "wrapper": "qxa",
  "access-denied": "qxH",
  "access-denied-illustration": "qxO",
  "application-card": "qxm",
  "presets-wrapper": "qxp",
  "error-wrapper": "qxx",
  "error-container": "qxw",
  "error-illustration": "qxN mb-32"
};

export default {
  "dropdown-icon": "ZSw",
  "dropdown": "ZSN",
  "download-button": "ZSv",
  "connections-banner": "ZSo",
  "recommendation-cards": "ZSn",
  "wrapper-with-cards": "ZSi",
  "transactions-wrapper": "ZSA",
  "separator": "ZSY",
  "with-transaction": "ZSh",
  "annual-billing-banner": "ZSf",
  "mt-132": "ZSK",
  "transactions-sidebar": "ZSG",
  "empty-state-wrapper": "ZSr"
};

export default {
  "container": "qjS",
  "content": "qjc",
  "setup-box": "qjq",
  "setup-wizard": "qjZ",
  "setup-success": "qjR",
  "setup-success-lottie": "qjQ",
  "setup-close-btn": "qje",
  "tray-connection": "qjB",
  "close-button": "qjE"
};

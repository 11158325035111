export default {
  "container": "qGy",
  "form-container": "qGU",
  "title": "qGj",
  "title-border": "qGW",
  "tabs-container": "qGu",
  "preview-container": "qrS",
  "email-preview-container": "qrc",
  "close-button": "qrq btn btn--icon-only btn--tertiary btn--large"
};

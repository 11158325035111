export default {
  "wrapper": "qjV",
  "integrations": "qjL",
  "description": "qjT body-2",
  "api": "qjz flex flex-column",
  "api-container": "qja flex large",
  "api-input": "qjH large",
  "api-btn": "qjO",
  "key-input": "qjm"
};

export default {
  "page-header": "ZcV",
  "header-breadcrumb": "ZcL",
  "tabs-container": "ZcT",
  "bottom-border": "Zcz",
  "tabs-separator": "Zca",
  "previous-request": "ZcH",
  "body-link": "ZcO",
  "border-top": "Zcm"
};

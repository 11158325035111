export default {
  "mt-16": "qyD",
  "title": "qyV",
  "avatar": "qyL",
  "edit-organization-form": "qyT",
  "dropdown-content": "qyz",
  "city-block": "qya",
  "zip-code-input": "qyH",
  "cancel-button": "qyO",
  "error-message": "qym",
  "nature-of-operations": "qyp",
  "error": "qyx",
  "legal-code": "qyw",
  "activity-type": "qyN"
};

export default {
  "multi-transfer": "ZcS",
  "container": "Zcc",
  "title": "Zcq",
  "subtitle": "ZcZ",
  "form": "ZcR",
  "form-title": "ZcQ",
  "files-buttons": "Zce",
  "files-buttons-item": "ZcB",
  "selected": "ZcE"
};

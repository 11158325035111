export default {
  "page-wrapper": "qui",
  "content-wrapper": "quA",
  "content": "quY",
  "period-field": "quh",
  "format-options": "quf",
  "format-option": "quK",
  "format-radio": "quG",
  "format-description": "qur body-2"
};

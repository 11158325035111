export default {
  "container": "qKz",
  "content": "qKa",
  "logos": "qKH",
  "logo": "qKO",
  "animation": "qKm",
  "title": "qKp title-2 mb-32",
  "avatar": "qKx",
  "dropdown": "qKw",
  "disabled": "qKN",
  "organization": "qKv",
  "actions": "qKo"
};

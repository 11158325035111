export default {
  "guests": "qAZ",
  "guests--upsell-screen": "qAR",
  "no-members": "qAQ",
  "teams-header": "qAe",
  "empty": "qAB",
  "invite-text": "qAE",
  "body": "qAd",
  "members": "qAI",
  "members-list": "qAt",
  "members-title": "qAM caption-bold",
  "member": "qAP",
  "details": "qAl"
};

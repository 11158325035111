export default {
  "bank-accounts": "qyR l-app-content__wrapper",
  "header-wrapper": "qyQ",
  "scrolled-top": "qye",
  "header": "qyB l-app-content__page-header",
  "create-account-button": "qyE btn btn--primary",
  "main": "qyd",
  "accounts": "qyI",
  "subtitle-container": "qyt",
  "subtitle": "qyM title-3 mb-16",
  "amount": "qyP body-2",
  "list--cashbeeSavings": "qyl"
};

export default {
  "wrapper": "qhl",
  "header": "qhX",
  "main": "qhC",
  "frequency": "qhk body-1",
  "radiogroup": "qhJ",
  "divider": "qhs",
  "option-wrapper": "qhF",
  "option-details-wrapper": "qhg",
  "option-label": "qhD",
  "option-name": "qhV",
  "option-details-text": "qhL",
  "spinner-wrapper": "qhT",
  "spinner": "qhz"
};

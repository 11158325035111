export default {
  "vat-number": "qjo body-2",
  "banner": "qjn",
  "container": "qji",
  "organization-profile": "qjA",
  "input": "qjY",
  "medium-input": "qjh medium",
  "small-input": "qjf small",
  "actions": "qjK",
  "company-info": "qjG",
  "company-info-section": "qjr",
  "company-address-section": "qjb",
  "company-country": "qjy",
  "company-billing-email": "qjU",
  "company-address": "qjj"
};

export default {
  "container": "qUw",
  "wrapper": "qUN",
  "grey-header-block": "qUv",
  "connect-app-header": "qUo",
  "connect-app-header-link": "qUn",
  "connect-app-header-content": "qUi",
  "connect-app-header-content-left": "qUA",
  "application-avatar": "qUY",
  "connect-app-header-cta": "qUh",
  "connect-app-header-meta": "qUf",
  "connect-app-content": "qUK",
  "connect-app-description": "qUG",
  "title-wrapper": "qUr",
  "title": "qUb title-1",
  "tag-line": "qUy body-2",
  "connect-app-body": "qUU"
};

export default {
  "page-container": "qYA",
  "form": "qYY",
  "close-button": "qYh btn btn--icon-only btn--tertiary btn--large",
  "preview": "qYf",
  "preview-padding": "qYK",
  "title": "qYG",
  "header": "qYr",
  "without-tabs": "qYb",
  "form-section": "qYy",
  "form-footer": "qYU",
  "disclaimer-text": "qYj"
};

export default {
  "container": "qWb",
  "header-cell": "qWy caption-bold",
  "col-8": "qWU",
  "table": "qWj",
  "cell": "qWW body-2",
  "cell-content": "qWu",
  "row": "quS",
  "empty": "quc",
  "quick-actions": "quq",
  "card-infos": "quZ",
  "card-digits": "quR",
  "empty-state": "quQ"
};

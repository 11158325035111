export default {
  "header": "qyv",
  "content": "qyo",
  "read-only": "qyn",
  "document-collection-banner": "qyi",
  "submit-banner": "qyA",
  "review-banner": "qyY",
  "disclaimer": "qyh",
  "title": "qyf",
  "card-container": "qyK",
  "representatives-section": "qyG",
  "disabled-href": "qyr"
};

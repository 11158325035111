export default {
  "signin": "quD",
  "form": "quV",
  "illustration": "quL",
  "purple": "quT",
  "mint": "quz",
  "mustard": "qua",
  "peach": "quH",
  "form-wrapper": "quO",
  "header-logo": "qum",
  "links": "qup",
  "no-account-label": "qux",
  "spinner-container": "quw",
  "main-image-container": "quN",
  "main-image": "quv",
  "wise-logo-container": "quo",
  "wise-logo": "qun"
};

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';

export default class EinvoicingConsentController extends Controller {
  @service homePage;
  @service intl;
  @service sentry;
  @service toastFlashMessages;

  @tracked einvoicingConsent = false;
  @tracked isConfirmClicked = false;

  get isConfirmedWithoutConsent() {
    return this.isConfirmClicked && !this.einvoicingConsent;
  }

  @action
  handleOnClose() {
    return this.homePage.replaceWithDefaultPage();
  }

  @action
  toggleEinvoicingConsent() {
    this.einvoicingConsent = !this.einvoicingConsent;

    if (this.einvoicingConsent) {
      this.isConfirmClicked = false;
    }
  }

  saveSettingsTask = task(async () => {
    if (this.einvoicingConsent) {
      try {
        this.model.einvoicingSettings.einvoicingConsentGiven = this.einvoicingConsent;

        await this.model.einvoicingSettings.save();

        this.toastFlashMessages.toastSuccess(this.intl.t('einvoicing-consent.success-toast'));
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        this.sentry.captureException(error);
      } finally {
        this.handleOnClose();
      }
    } else {
      this.isConfirmClicked = true;
    }
  });
}

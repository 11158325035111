export default {
  "container": "qbX",
  "form-container": "qbC",
  "title": "qbk",
  "title-border": "qbJ",
  "tabs-container": "qbs",
  "preview-container": "qbF",
  "email-preview-container": "qbg",
  "close-button": "qbD btn btn--icon-only btn--tertiary btn--large"
};

export default {
  "page-wrapper": "qxv",
  "container": "qxo",
  "sidebar": "qxn",
  "search-input": "qxi",
  "organizations-list": "qxA",
  "content": "qxY",
  "no-result": "qxh",
  "lottie-illustration": "qxf",
  "accounting-header": "qxK",
  "logo": "qxG",
  "accounting-title": "qxr"
};

export default {
  "container": "qWk",
  "security-method": "qWJ",
  "icon": "qWs",
  "body": "qWF",
  "status": "qWg body-2",
  "device": "qWD",
  "device-icon": "qWV",
  "subtitle": "qWL body-2",
  "current": "qWT"
};

export default {
  "splash-landing": "qNi",
  "container": "qNA",
  "options": "qNY",
  "physical": "qNh",
  "card-label": "qNf",
  "physical-cta-container": "qNK",
  "physical-cta": "qNG",
  "coming-soon": "qNr"
};

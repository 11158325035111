export default {
  "details": "qhh",
  "header": "qhf",
  "avatar": "qhK mr-16",
  "header-beneficiary": "qhG",
  "header-beneficiary-activity": "qhr",
  "header-beneficiary-details": "qhb",
  "header-beneficiary-details-recurring": "qhy",
  "infos": "qhU",
  "infos-title": "qhj",
  "infos-empty": "qhW",
  "infos-list": "qhu",
  "infos-list-item": "qfS",
  "infos-list-item-left": "qfc",
  "infos-list-item-right": "qfq",
  "infos-list-item-attachment": "qfZ",
  "infos-transactions": "qfR",
  "actions": "qfQ"
};

export default {
  "main-container": "qvc",
  "main": "qvq",
  "main-wrapper": "qvZ",
  "close-button": "qvR",
  "active-block": "qvQ",
  "title": "qve",
  "list-options": "qvB",
  "card": "qvE",
  "card-container": "qvd",
  "card-image": "qvI",
  "card-tiles": "qvt"
};

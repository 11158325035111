export default {
  "container": "qrw",
  "form-container": "qrN",
  "preview-container": "qrv",
  "pdf-preview-container": "qro",
  "content": "qrn",
  "title": "qri",
  "tabs-container": "qrA",
  "close-button": "qrY btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qrh",
  "header": "qrf",
  "without-tabs": "qrK"
};

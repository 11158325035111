export default {
  "member-details": "qiT",
  "header": "qiz",
  "header-img": "qia",
  "invited-persona": "qiH",
  "revoked-persona": "qiO",
  "invited-icon": "qim",
  "revoked-icon": "qip",
  "dropdown": "qix",
  "header-body": "qiw",
  "name": "qiN title-3",
  "email": "qiv caption-bold",
  "actions": "qio body-2",
  "actions-cards": "qin",
  "actions-transactions": "qii",
  "body": "qiA",
  "expense-permissions-section": "qiY",
  "body-title": "qih title-4",
  "body-attr": "qif",
  "body-label": "qiK",
  "attr-buttons": "qiG",
  "body-role": "qir small",
  "details-actions": "qib",
  "actions-item": "qiy",
  "disclaimer": "qiU",
  "permissions": "qij",
  "permission": "qiW",
  "permission-label": "qiu",
  "permission-check": "qAS",
  "permission-missing": "qAc",
  "editable-section": "qAq"
};

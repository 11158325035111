export default {
  "team": "qfA",
  "no-members": "qfY",
  "teams-header": "qfh",
  "header-search": "qff",
  "search-bar": "qfK",
  "invite": "qfG body-2",
  "invite-text": "qfr",
  "empty-illustration": "qfb",
  "empty-title": "qfy title-3",
  "empty-description": "qfU body-2",
  "pagination-footer": "qfj",
  "filter-search": "qfW",
  "fullsize": "qfu",
  "tabs-container": "qKS",
  "tabs-separator": "qKc"
};

export default {
  "container": "qUS",
  "wrapper": "qUc",
  "grey-header-block": "qUq",
  "connect-app-header": "qUZ",
  "connect-app-header-link": "qUR",
  "connect-app-header-content": "qUQ",
  "connect-app-header-content-default": "qUe",
  "connect-app-header-content-left": "qUB",
  "button-block": "qUE",
  "connect-app-body": "qUd",
  "connect-app-body-left-content": "qUI",
  "connect-app-body-sidebar": "qUt"
};

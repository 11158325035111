export default {
  "container": "qAu",
  "compact-header": "qYS",
  "visible": "qYc",
  "page-container": "qYq",
  "main": "qYZ",
  "tabs": "qYR",
  "tabs-nav": "qYQ",
  "tabs-panels": "qYe",
  "tabs-panel": "qYB body-2",
  "sidebar": "qYE",
  "prismic-content": "qYd"
};

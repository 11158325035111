export default {
  "page-container": "qhZ",
  "form": "qhR",
  "close-button": "qhQ btn btn--icon-only btn--tertiary btn--large",
  "preview": "qhe",
  "preview-padding": "qhB",
  "title": "qhE",
  "header": "qhd",
  "without-tabs": "qhI",
  "form-section": "qht",
  "form-footer": "qhM",
  "disclaimer-text": "qhP"
};

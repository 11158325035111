export default {
  "accounts": "qwe",
  "header-subtitle": "qwB title-4",
  "subtitle-container": "qwE",
  "subtitle": "qwd title-3 mb-16",
  "amount": "qwI body-2",
  "list--cashbeeSavings": "qwt",
  "connections-banner": "qwM",
  "list": "qwP",
  "icon": "qwl",
  "badge": "qwX",
  "discover-tile": "qwC"
};

export default {
  "supplier-invoices-page-header": "qub",
  "filters-container": "quy",
  "filters": "quU",
  "bulk-actions": "quj",
  "item-count": "quW",
  "higher-index": "quu",
  "title-wrapper": "ZSS",
  "title": "ZSc",
  "subtitle": "ZSq",
  "table-container": "ZSZ",
  "file-dropzone": "ZSR",
  "dropzone-visible": "ZSQ",
  "header-actions": "ZSe",
  "header-wrapper": "ZSB"
};

export default {
  "page-wrapper": "qrG",
  "content-wrapper": "qrr",
  "content": "qrb",
  "period-field": "qry",
  "format-options": "qrU",
  "format-option": "qrj",
  "format-radio": "qrW",
  "format-description": "qru body-2"
};

export default {
  "container": "qUC",
  "link-grey": "qUk",
  "slack-section-title": "qUJ title-4 mb-8",
  "edit-channel-text": "qUs body-2 mb-16",
  "rule-section-title": "qUF title-4 mb-16",
  "rule-cards-container": "qUg",
  "edit-slack-container": "qUD",
  "rule-card": "qUV",
  "add-rule-container": "qUL",
  "add-rule-link-button": "qUT",
  "add-rule-text": "qUz",
  "empty-state": "qUa",
  "empty-state-textblock": "qUH",
  "empty-state-title": "qUO title-2",
  "empty-state-description": "qUm body-2",
  "empty-state-illustration": "qUp",
  "rules": "qUx"
};

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import {
  INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING,
  INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER,
} from 'qonto/constants/insurance-hub';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InsuranceHubPoliciesController extends Controller {
  @service insuranceManager;
  @service organizationManager;
  @service router;
  @service store;

  @tracked highlight;

  get insuranceContracts() {
    let contracts = this.model.fetchInsuranceContractsTask.lastSuccessful?.value ?? [];

    // Sort contracts avoiding mutating the original array
    let sortedContracts = [...contracts].sort((a, b) => {
      let statusA = INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER.indexOf(
        INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING[a.status]
      );
      let statusB = INSURANCE_CONTRACT_INTERNAL_STATUS_ORDER.indexOf(
        INSURANCE_CONTRACT_INTERNAL_STATUS_MAPPING[b.status]
      );

      if (statusA !== statusB) {
        return statusA - statusB;
      }

      return a.name.localeCompare(b.name);
    });

    return sortedContracts;
  }

  get insuranceProvider() {
    let provider = this.fetchProviderTask.lastSuccessful?.value;

    return {
      name: provider?.data.name,
      logo: provider?.data.logo,
    };
  }

  get hasNoResult() {
    return (
      this.insuranceContracts.length === 0 &&
      !this.model.fetchInsuranceContractsTask.isRunning &&
      !this.model.fetchInsuranceContractsTask.last.isError
    );
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;

    let contract = this.store.peekRecord('insurance-contract', itemId);

    this.fetchProviderTask.perform(contract.providerSlug).catch(ignoreCancelation);
  }

  fetchProviderTask = dropTask(async slug => {
    return await this.insuranceManager.getInsuranceProvider(slug);
  });
}

export default {
  "body": "qfa",
  "members": "qfH",
  "members-results": "qfO",
  "members-list": "qfm",
  "members-title": "qfp caption-bold",
  "member": "qfx",
  "empty-illustration": "qfw",
  "empty-title": "qfN title-3",
  "empty-description": "qfv body-2",
  "invitable-members": "qfo",
  "details": "qfn",
  "pagination-footer": "qfi"
};

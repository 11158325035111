export default {
  "container": "qnK",
  "page-wrapper": "qnG",
  "header": "qnr",
  "back-button-wrapper": "qnb",
  "header-inner": "qny",
  "header-main-wrapper": "qnU",
  "header-main": "qnj",
  "header-right": "qnW",
  "content-wrapper": "qnu",
  "content": "qiS",
  "content-body": "qic",
  "sidebar": "qiq"
};

export default {
  "container": "qKP",
  "content": "qKl",
  "logos": "qKX",
  "logo": "qKC",
  "animation": "qKk",
  "title": "qKJ title-2 mb-32",
  "avatar": "qKs",
  "actions": "qKF mb-32",
  "footer": "qKg body-2"
};

export default {
  "mandates": "qfe",
  "mandates-empty": "qfB",
  "header": "qfE",
  "header-empty": "qfd",
  "header-content": "qfI",
  "search": "qft",
  "search-bar": "qfM",
  "search-spinner": "qfP",
  "body": "qfl",
  "isEmpty": "qfX",
  "left-section": "qfC",
  "mandates-list": "qfk",
  "mandate-suspended": "qfJ",
  "list-title": "qfs caption-bold",
  "list-empty": "qfF",
  "empty-illustration": "qfg",
  "empty-title": "qfD title-3",
  "empty-description": "qfV body-2",
  "body-details": "qfL",
  "l-app-content__page-header": "qfT",
  "pagination-footer": "qfz"
};

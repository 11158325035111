export default {
  "container": "qbR",
  "form-container": "qbQ",
  "preview-container": "qbe",
  "pdf-preview-container": "qbB",
  "content": "qbE",
  "title": "qbd",
  "tabs-container": "qbI",
  "close-button": "qbt btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qbM",
  "header": "qbP",
  "without-tabs": "qbl"
};

export default {
  "page-wrapper": "qvC",
  "back-button": "qvk",
  "abort-button": "qvJ",
  "container": "qvs",
  "subtitle": "qvF",
  "button": "qvg",
  "panel-list": "qvD",
  "panel-list-item": "qvV",
  "card-illustration": "qvL"
};

export default {
  "title": "qWQ title-3",
  "personal-profile-container": "qWe",
  "personal-profile-section": "qWB",
  "member-info-title": "qWE",
  "kyc-complete-profile": "qWd",
  "info-fields": "qWI",
  "field": "qWt",
  "address-fields": "qWM",
  "field-span": "qWP",
  "input": "qWl",
  "cancel": "qWX"
};

export default {
  "page-wrapper": "qvj",
  "container": "qvW",
  "spinner-container": "qvu",
  "left-panel": "qoS",
  "form": "qoc",
  "left-content": "qoq",
  "form-footer": "qoZ",
  "delete-button": "qoR",
  "header": "qoQ",
  "preview-container": "qoe",
  "email-preview": "qoB",
  "close-button": "qoE btn btn--icon-only btn--tertiary btn--large"
};
